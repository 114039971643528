<template>
    <app-navigation :heading="activeProject.name"
                    class="mb-4">
        <template #toolbar>
            <v-spacer></v-spacer>
            <view-selector :project-id="activeProject._id"></view-selector>
        </template>
        <div class="steps-container d-flex fill-height">
            <v-col v-for="step in steps"
                   :key="step._id"
                   class="step-column">
                <h4 class="text-center mb-2">{{step.name}}
                    <span v-if="step.submissions && step.submissions.length">({{step.submissions.length}})</span>
                </h4>
                <submission-summary-card v-for="sub in step.submissions"
                                         :key="sub._id"
                                         :submission="sub"
                                         :to="`/projects/${activeProject._id}/submissions/${sub._id}`">
                    <tbody>
                        <tr>
                            <td>Created:</td>
                            <td><em>{{$displayDate(sub.submissionCreatedOn)}}</em></td>
                        </tr>
                        <tr>
                            <td class="pr-3">Assigned to:</td>
                            <td><em>{{sub.assignee.firstName}} {{sub.assignee.surname}}</em></td>
                        </tr>
                        <tr>
                            <td>Assigned on:</td>
                            <td><em>{{$displayDate(sub.assignedOn)}}</em></td>
                        </tr>
                    </tbody>
                </submission-summary-card>
            </v-col>
        </div>
    </app-navigation>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';
    import beforeEnterGuard from '../router/beforeEnterGuard';

    import appNavigation from '@/components/app-navigation';
    import viewSelector from '@/modules/projects/components/view-selector';
    import submissionSummaryCard from '@/components/submission-summary-card';

    export default {
        name: 'assigned-board',
        components: {
            appNavigation,
            viewSelector,
            submissionSummaryCard,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            activeSubmissions: undefined,
        }),
        computed: {
            steps() {
                const active = this.activeSubmissions;
                return !active
                    ? this.activeProject.steps
                    : this.activeProject.steps.map(s => ({
                          ...s,
                          ...(active.find(a => a._id === s._id) || {}),
                      }));
            },
            ...mapState('organisation/projects', ['activeProject']),
            ...mapGetters('organisation/projects', ['activeProjectUrl']),
        },
        methods: {
            loadActiveSubs() {
                this.$store.commit('updateStatus', { isLoading: true });
                this.$http
                    .get(`${this.activeProjectUrl}/submissions/active`)
                    .then(({ data: subs }) => {
                        this.activeSubmissions = subs;
                        this.$store.commit('updateStatus', { isLoading: false });
                    });
            },
        },
        beforeRouteUpdate (to, from, next) {
            return beforeEnterGuard(this.$store, to, from, next, true);
        },
        created() {
            document.getElementsByTagName('html')[0].classList.add('overflow');
            this.loadActiveSubs();
        },
        destroyed() {
            document.getElementsByTagName('html')[0].classList.remove('overflow');
        },
    };
</script>


<!-- Shares styles with finalised-board-->
<style>
    .steps-container {
        background-color: #e7e7e7;
        border-top: 0;
        padding: 4px;
        padding-bottom: 0;
        overflow-y: visible;
    }

    .step-column {
        width: calc(25vw - 6px);
        min-width: calc(25vw - 6px);
        background-color: #fafafa;
        border-right: 4px solid #e7e7e7;
        border-bottom: 4px solid #e7e7e7;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .step-column:last-child {
        border-right: 0;
    }
</style>